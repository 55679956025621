import React, {Fragment, useEffect, useState} from "react";
import {ButtonIcon, Loading} from "@vokymlak/kabinet-ui";
import ModalEditTimeWorkCategory from "./ModalEditTimeWorkCategory";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ModalAddTimeWorkCategory from "./ModalAddTimeWorkCategory";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

export const StrictModeDroppable = ({children, ...props}) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

function TimeWorkCategoriesPage(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    onDragEnd,
  } = props;

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Категории работ
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({list.length})
              </span>
            )}
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>


        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId={"droppable"}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {!!list &&
                        list.map((item, index) => (
                          <Draggable
                            draggableId={String(item.uuid)}
                            index={index}
                            key={"draggable_" + item.uuid}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Fragment key={"item_" + item.uuid}>
                                  <li
                                    className={
                                      isUnroll(item.uuid)
                                        ? "List__item active"
                                        : "List__item"
                                    }
                                    onClick={() => changeUnroll(item.uuid)}
                                  >
                                    <div className="Item__title">
                                      <div className="Element__title" style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}>
                                        <div
                                          style={{
                                            display: "inline-block",
                                            height: 10,
                                            width: 10,
                                            borderRadius: 10,
                                            backgroundColor: !!item.color ? item.color : "#9e9e9e",
                                            marginRight: 8
                                          }}/>
                                        {index + 1}
                                        {". "}
                                        {item.name}
                                      </div>
                                    </div>
                                    <div className="Item__settings">
                                      <div className="Element__datetime">
                                        {item.is_considered && (
                                          <div
                                            style={{
                                              display: "inline-block",
                                              backgroundColor: "#202020",
                                              padding: "1px 4px 2px",
                                              fontSize: 11,
                                              borderRadius: 4,
                                              color: "white",
                                              fontWeight: 700,
                                              marginLeft: 8,
                                            }}
                                          >
                                            Учитывать время
                                          </div>
                                        )}
                                        {item.is_unblock_work && (
                                          <div
                                            style={{
                                              display: "inline-block",
                                              backgroundColor: "#0061ff",
                                              padding: "1px 4px 2px",
                                              fontSize: 11,
                                              borderRadius: 4,
                                              color: "white",
                                              fontWeight: 700,
                                              marginLeft: 8,
                                            }}
                                          >
                                            Доступ к работе
                                          </div>
                                        )}
                                        {!!item.min_operating_time && (
                                          <div
                                            style={{
                                              display: "inline-block",
                                              backgroundColor: "#202020",
                                              padding: "1px 4px 2px",
                                              fontSize: 11,
                                              borderRadius: 4,
                                              color: "white",
                                              fontWeight: 700,
                                              marginLeft: 8,
                                            }}
                                          >
                                            {item.min_operating_time}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </li>

                                  {isUnroll(item.uuid) && (
                                    <li className="List__content">
                                      <div className="Content__header">
                                        <div className="Header__title">
                                        </div>
                                        <div className="Header__settings">
                                          <ButtonIcon
                                            tooltipText={"Редактировать"}
                                            iconName={"custom-edit"}
                                            iconColor={"#202020"}
                                            size={36}
                                            style={{marginLeft: 12}}
                                            onClick={() => setModalItemEdit(item)}
                                          />
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </Fragment>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalEditTimeWorkCategory
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalAddTimeWorkCategory
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default TimeWorkCategoriesPage;
