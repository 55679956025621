import React, {Fragment, useEffect, useState} from "react";
import {ROOT_URL} from "../../../config/url";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import ModalNomenclaturePhotoEdit from "./ModalNomenclaturePhotoEdit";
import {useViewer} from "../../../components/Viewer";
import ImageUploadB64 from "../../../functions/ImageUpload64";
import {dndArray} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

export const StrictModeDroppable = ({children, ...props}) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props} direction="horizontal">{children}</Droppable>;
};

function NomenclaturePhotosList(props) {
  const {object, isEdit, setIsUpdateList, isUpdateList} = props;
  const [list, setList] = useState(null);

  useEffect(() => {
    setList(object.photos);
  }, [object]);

  const {viewerChildren} = useViewer();

  const changeViewer = (image, mimeType) => {
    viewerChildren(image, mimeType);
  };

  // const {
  //   list: [list],
  //   update: [isUpdateList, setIsUpdateList],
  // } = useList({
  //   apiUrl:"/api/nomenclature/" + object.uuid + "/photos",
  //   rootUrl:ROOT_URL
  // });

  const [modalNomenclaturePhotoEdit, setModalNomenclaturePhotoEdit] = useState(null);

  function isDatePattern(str) {
    const datePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
    const match = datePattern.test(str);
    return match;
  }

  const onDragEnd = (result) => {
    if (result.destination) {
      const oldIndex = result.source.index;
      const newIndex = result.destination.index;
      const objectId = result.draggableId;

      let object = null;

      if (!!list) {
        list.map((item) => {
          if (objectId === item.uuid) {
            object = {...item};
          }
        });

        setList([...dndArray(list, oldIndex, newIndex)]);

        axios
          .get("/api/nomenclature-photo/" + object.uuid + "/index/" + newIndex, {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          })
          .then(() => {
            setIsUpdateList(!isUpdateList);
          })
          .catch(() => {
            setIsUpdateList(!isUpdateList);
          });
      }
    }
  };

  return (
    !!list && (
      <Fragment>
        <div className={styleContent._block}>
          <div className={styleContent._block__title}>Изображения</div>
          <div className={styleContent._block__content}>
            <DragDropContext onDragEnd={onDragEnd}>
              <StrictModeDroppable droppableId={"droppable"}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      display: 'flex',
                      overflowX: 'auto',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {list.map((photo, index) => (
                      <Draggable
                        draggableId={String(photo.uuid)}
                        index={index}
                        key={"draggable_" + photo.uuid}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              cursor: 'pointer',
                            }}
                          >
                            <Fragment
                              key={"item" + ":" + photo.uuid}>

                              {!isDatePattern(photo.data) && (
                                <div
                                  className={styleCard._block + " " + styleCard._block_hover}
                                  style={{
                                    minWidth: 12 + 72,
                                    height: 12 + 72,
                                    padding: 6
                                  }}
                                  onContextMenu={isEdit ? (event) => (event.preventDefault(), setModalNomenclaturePhotoEdit(photo)) : () => null}
                                  onClick={(!!photo.mime_type && (photo.mime_type === "image/jpeg" || photo.mime_type === "image/png"))
                                    ? (event) => (event.preventDefault(), changeViewer(photo.data, photo.mime_type))
                                    : (event) => event.preventDefault()
                                  }>
                                  {!!photo.data && !!photo.mime_type && (photo.mime_type === "image/jpeg" || photo.mime_type === "image/png") && (
                                    <>
                                      <img
                                        src={`data:${photo.mime_type};base64, ${photo.data}`}
                                        height={72}
                                        style={{
                                          borderRadius: 5
                                        }}
                                        alt={""}
                                      />
                                    </>
                                  )}
                                </div>
                              )}
                              {!!isDatePattern(photo.data) && (
                                <div
                                  className={styleCard._block + " " + styleCard._block_hover}
                                  style={{
                                    minWidth: 12 + 72,
                                    height: 12 + 72,
                                    padding: 6
                                  }}
                                  onContextMenu={(event) => (event.preventDefault(), setModalNomenclaturePhotoEdit(photo))}
                                  onClick={
                                    () =>
                                      changeViewer(
                                        <ImageUploadB64
                                          url={ROOT_URL + "/api/nomenclature/photo/" + photo.uuid + "/b64"}/>
                                      )}
                                >
                                  <ImageUploadB64
                                    url={ROOT_URL + "/api/nomenclature/photo/" + photo.uuid + "/b64"} height={72}
                                    width={72}/>
                                </div>
                              )}
                            </Fragment>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </div>
        </div>

        {!!modalNomenclaturePhotoEdit && (
          <ModalNomenclaturePhotoEdit
            nomenclaturePhoto={modalNomenclaturePhotoEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalNomenclaturePhotoEdit(null)}
          />
        )}
      </Fragment>
    ));
}

export default NomenclaturePhotosList;
